<template>
  <div>
    <div>
      <div class="flex flex-wrap p-3">
        <div class="w-full" v-if="toggleScreenSize">
          <div :class="`${complianceDetails.status.toLowerCase()} rounded-full text-sm text-center px-3 py-1 inline-block font-semibold`">
            {{ getStatusData(complianceDetails.status) }}
          </div>
        </div>

        <div v-if="toggleScreenSize" class="w-full mt-5 hidden lg:block">
          <p> {{`${complianceMonth} Compliance`}} </p>
          <p class="mt-2 text-3xl font-bold">  {{ complianceDetails.clinicName }} </p>
          <p class="mt-2 text-xl">Step 1 of 2 - File Uploads</p>
          <p class="text-sm mt-1">Files supported for upload: PNG, JPG, JPEG, HEIC, PDF </p>
          <vs-divider />
        </div>

        <div v-if="toggleScreenSize" class="w-full mt-5 lg:hidden">
          <p> {{ `${complianceMonth} Compliance` }} </p>
          <p class="mt-2 text-3xl font-bold"> {{ complianceDetails.clinicName }} </p>
          <p class="mt-2">Step 1 of 2 - File Uploads</p>
          <p class="text-sm mt-1">Files supported for upload: PNG, JPG, JPEG, HEIC, PDF </p>
          <vs-divider />
        </div>

        <vs-popup
          class="overflow-hidden"
          :key="1"
          title="Image Preview"
          :active.sync="popupImageActivo"
        >
          <div class="w-full">
            <div class="w-full">
              <template v-if="imagePreviewSrc.src.indexOf('pdf') !== -1">
                <vs-image
                  class="w-full"
                  :key="imagePreviewSrc.src"
                  src="@/assets/images/icon-pdf.svg"
                />
              </template>

              <vs-image
                v-else
                class="w-full"
                :key="imagePreviewSrc.src"
                :src="imagePreviewSrc.src"
              />
            </div>
            <div class="w-full flex justify-end">
              <vs-button
                size="lg"
                color="danger"
                class="text-right mt-5"
                @click="removePreview"
                >Remove</vs-button
              >
            </div>
          </div>
        </vs-popup>
      
        <div class="w-full">
          <div class="w-full flex mt-5 flex-no-wrap">
            <div
              v-if="!isStocktake || !complianceDrugbook.length"
              class="w-full"
            >
              <!-- <div class="w-full hidden md:block">
                <p class="text-2xl font-bold">DrugBook</p>
                <p>
                  Please complete a stocktake to confirm your drugbook balances.
                </p>
              </div> -->

              <div class="relative w-full">

                <div class="w-8/12">
                  <p class="md:text-2xl md:font-bold text-xl font-bold"> 
                    DrugBook 
                  </p>
                  <p v-if="!isDigitalDrubook" class="text-sm"> 
                    Please upload photos of your drugbook from last month 
                  </p>
                  <p v-else class="text-sm"> 
                    A PDF export of your Drugbook records for last 30 days has been generated. Please review and make necessary changes to your Drugbook before submitting this compliance check.
                  </p>
                </div>
                <progress-bar class="mt-5" v-if="isDigitalDrubook" :month="monthCompliance" :year="yearCompliance" :clinicId="complianceDetails.clinicId"/>

                <div class="items-center lg:pr-5 absolute top-0 right-0">
                  <div class="vs-con-loading__container relative">
                    <label class="upload-btn bg-white text-center px-6 py-1 flex items-center rounded-lg text-primary font-medium">
                      <span>
                        <span class="vs-button-backgroundx vs-button--background"></span>
                        <span class="vs-button-text vs-button--text"> Upload </span>
                        <input
                          type="file"
                          accept="image/*,application/pdf,.heic"
                          @change="uploadImage($event, 'drug')"
                          id="drugs-file-input"
                          hidden
                          multiple
                        />
                        <span class="vs-button-linex"></span>
                      </span>
                    </label>
                    <!-- <vs-button v-else type="border" class="h-12 text-center py-2 text-sm" @click="genratePdfReport">
                      Export Drugbook
                    </vs-button> -->
                  </div>
                  <!-- <vs-button @click="onStocktake()" color="success" type="border" v-if="!completeComplianceCheck"
                    :disabled="totalTreatmentsWithNoEntry > 0">
                    Stocktake
                  </vs-button> -->
                </div>

              </div>

              <div
                v-if="errors.includes('drug') && !images.drug.length"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
              >
                <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2"
                  >Upload at least one(1) image</span
                > 
              </div>

              <div class="w-full flex space-x-4 flex-wrap my-6">
                <div
                  :key="image.photoLink"
                  class="w-24"
                  v-for="(image, index) in images.drug"
                >
                  <div
                    @click="ImagePreview(image.photoLink, 'drug')"
                    v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index"
                  >
                    <vs-image
                      class="w-full hover:shadow-lg hover-scale object-cover"
                      :src="image.photoLink"
                    />
                  </div>
                  <template
                  v-if="image.photoLink.indexOf('pdf') > -1"
                  >
                    <div
                    v-if="!isDigitalDrubook"
                      :key="index"
                      @click="DownloadPdf({ url: image.photoLink, name: 'drug' })"
                    >
                      <img
                        class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                        :key="image.index"
                        src="@/assets/images/icon-pdf.svg"
                      />
                      <p class="text-center text-sm">
                        {{complianceDetails.clinicName}} <br/>
                        {{` [Drugbook] - ${complianceMonth} ${complianceYear}`}}
                      </p>
                    </div>
                    <div
                      :key="index+'digital'"
                      v-else
                      @click="DownloadPdf({ url: image.photoLink, name: `${complianceDetails.clinicName} - Drugbook - Monthly Compliance` })"
                    >
                      <img
                        class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                        :key="image.index"
                        src="@/assets/images/icon-pdf.svg"
                      />
                      <p class="text-center text-sm">
                        {{complianceDetails.clinicName}} <br/>
                        {{` [Drugbook] - ${complianceMonth} ${complianceYear}`}}
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isStocktake && complianceDrugbook.length > 0"
            class="w-full flex mt-5 flex-no-wrap"
          >
            <div class="w-full">
              <div v-if="toggleScreenSize" class="flex justify-between w-full">
                <div>
                  <p class="text-xl font-bold md:text-2xl md:font-bold">
                    DrugBook
                  </p>
                  <p class="text-sm">
                    Please complete a stocktake to confirm your drugbook
                    balances.
                  </p>
                </div>
                <div
                  v-if="!completeComplianceCheck"
                  class="flex items-center lg:p-5 lg:flex"
                >
                  <vs-button
                    @click="onStocktake()"
                    color="success"
                    type="border"
                    v-if="!completeComplianceCheck"
                    :disabled="totalTreatmentsWithNoEntry > 0"
                  >
                    Stocktake
                  </vs-button>
                </div>
              </div>

              <div
                v-if="errors.includes('drug')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
              >
                <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2"
                  >Please complete a stocktake to confirm your drugbook
                  balances.</span
                >
              </div>
              <div class="w-full flex space-x-4 flex-wrap my-6">
                <div
                  :key="image.photoLink"
                  class="w-24"
                  v-for="(image, index) in images.drug"
                >
                  <div
                    @click="ImagePreview(image.photoLink, 'drug')"
                    v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index"
                  >
                    <vs-image
                      class="w-full hover:shadow-lg hover-scale object-cover"
                      :src="image.photoLink"
                    />
                  </div>
                  <template
                      v-if="image.photoLink.indexOf('pdf') > -1"
                    >
                      <div
                      v-if="!isDigitalDrubook"
                        :key="index"
                        @click="DownloadPdf({ url: image.photoLink, name: 'drug' })"
                      >
                        <img
                          class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                        <p class="text-center text-sm">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} ${complianceYear}`}}
                        </p>
                      </div>
                      <div
                        :key="index+'digital'"
                        v-else
                        @click="DownloadPdf({ url: image.photoLink, name: `${complianceDetails.clinicName} - Drugbook - Monthly Compliance` })"
                      >
                        <img
                          class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                        <p class="text-center text-sm">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} ${complianceYear}`}}
                        </p>
                      </div>
                  </template>
                </div>
              </div>
              <div class="md:mt-4">
                <!-- <complainceDrugbookTable
                  @onEdit="handleOnEdit"
                  @fetchStocktake="getClinicDrugbookProducts" :isComplianceCheck="false"
                  :complianceDrugbook="complianceDrugbook" v-if="
                    totalTreatmentsWithNoEntry === 0 && completeComplianceCheck
                  " /> -->

                <vs-table
                  v-if="
                    totalTreatmentsWithNoEntry === 0 && completeComplianceCheck
                  "
                  class="hidden md:block"
                  ref="table"
                  :sst="true"
                  :total="totalDocs"
                  :data="complianceDrugbook"
                >
                  <template slot="thead">
                    <vs-th style="background: #bcd6ce" sort-key="clinicName"
                      >PRODUCT NAME</vs-th
                    >
                    <vs-th style="background: #bcd6ce" sort-key="orderYear"
                      >DRUGBOOK BALANCE</vs-th
                    >
                    <vs-th style="background: #bcd6ce" sort-key="orderMonth"
                      >COUNTED QUANTITY</vs-th
                    >
                    <vs-th style="background: #bcd6ce" sort-key="status"
                      >VARIANCE</vs-th
                    >
                    <vs-th style="background: #bcd6ce" sort-key="status"
                      >NOTES</vs-th
                    >
                    <vs-th style="background: #bcd6ce">ACTIONS</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td :data="data[indextr].productName">{{
                        data[indextr].productName
                      }}</vs-td>
                      <vs-td :data="data[indextr].drugBookBalance">{{
                        data[indextr].drugBookBalance
                      }}</vs-td>
                      <vs-td :data="data[indextr].countedQuality">{{
                        data[indextr].countedQuality
                      }}</vs-td>
                      <vs-td
                        :data="data[indextr].variance"
                        style="color: #e02020"
                      >
                        <p v-if="data[indextr].variance == 0">
                          {{
                            `${data[indextr].variance}${
                              data[indextr].unit === "ml"
                                ? data[indextr].unit
                                : "u"
                            }`
                          }}
                        </p>
                        <p v-else>
                          {{
                            `${
                              data[indextr].drugBookBalance >
                              data[indextr].countedQuality
                                ? `-${data[indextr].variance}`
                                : `+${Math.abs(data[indextr].variance)}`
                            }${
                              data[indextr].unit === "ml"
                                ? data[indextr].unit
                                : "u"
                            }`
                          }}
                        </p>
                      </vs-td>
                      <!-- <vs-td :data="data[indextr].variance" style="color: #e02020">{{
                          `${
                            data[indextr].drugBookBalance > data[indextr].countedQuality
                              ? `-${data[indextr].variance}`
                              : `+${Math.abs(data[indextr].variance)}`
                          }`
                        }}</vs-td> -->
                      <vs-td :data="data[indextr].notes">{{
                        data[indextr].notes
                      }}</vs-td>
                      <vs-td :data="data[indextr].productName">
                        <vs-button
                          @click="toggleModal(data[indextr])"
                          type="border"
                          >Edit</vs-button
                        >
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>

                <div
                  v-if="
                    toggleScreenSize &&
                    totalTreatmentsWithNoEntry === 0 &&
                    completeComplianceCheck
                  "
                  class="md:hidden"
                >
                  <div
                    class="shadow-md p-2 my-2 rounded-lg"
                    v-for="drugData in complianceDrugbook"
                    :key="drugData._id"
                  >
                    <!-- <div class="flex justify-items-center w-full"> -->
                    <!-- <div class="w-1/2"> -->
                    <div class="flex justify-items-center w-full">
                      <div
                        style="color: #0e4333"
                        class="font-semibold text-sm leading-loose py-1 w-1/2"
                      >
                        {{ drugData.productName }}
                      </div>
                      <div
                        @click="toggleModal(drugData)"
                        style="color: #0e4333"
                        class="font-semibold text-sm leading-loose cursor-auto my-1 text-right w-1/2"
                      >
                        <vs-icon class="cursor-auto" icon="edit" />
                      </div>
                    </div>
                    <div class="flex justify-items-center w-full">
                      <div
                        style="color: #6e6b7b"
                        class="font-medium text-xs my-1 w-1/2"
                      >
                        Counted Quantity
                      </div>
                      <div
                        style="color: #6e6b7b"
                        class="font-medium text-xs my-1 text-right w-full"
                      >
                        {{ `${drugData.countedQuality || "0"}${"u"}` }}
                      </div>
                    </div>
                    <div class="flex justify-items-center w-full">
                      <div
                        style="color: #6e6b7b"
                        class="font-medium text-xs my-1 w-1/2"
                      >
                        Drugbook Balance
                      </div>
                      <div
                        style="color: #6e6b7b"
                        class="w-1/2 font-medium text-xs my-1 text-right"
                      >
                        {{ `${drugData.drugBookBalance || "0"}${"u"}` }}
                      </div>
                    </div>
                    <div class="flex justify-items-center w-full">
                      <div
                        style="color: #e02020"
                        class="font-medium text-xs my-1 w-1/2"
                      >
                        Variance
                      </div>
                      <div
                        style="color: #e02020"
                        class="w-1/2 font-medium text-xs my-1 text-right"
                      >
                        <p v-if="drugData.variance == 0">
                          {{
                            `${`${Math.abs(drugData.variance)}`}${
                              drugData.unit === "ml" ? drugData.unit : "u"
                            }`
                          }}
                        </p>
                        <p v-else>
                          {{
                            `${
                              drugData.drugBookBalance > drugData.countedQuality
                                ? `-${drugData.variance}`
                                : `+${Math.abs(drugData.variance)}`
                            }${drugData.unit === "ml" ? drugData.unit : "u"}`
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="flex justify-items-center w-full">
                      <div
                        style="color: #6e6b7b"
                        class="font-medium text-xs my-1 w-1/2"
                      >
                        Notes
                      </div>
                      <div
                        style="color: #6e6b7b"
                        class="w-1/2 font-medium text-xs my-1 text-right"
                      >
                        {{ drugData.notes }}
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>

                <!-- <div v-show="!isMobileView && isMobileView !== null">
                    <vs-popup
                    class="holamundo"
                    title="Edit Record"
                    :active.sync="popupActive"
                    :key="2"
                    v-if="!isMobileView && isMobileView !== null"
                    >
                    <editStocktakeForm :selectedStockTake="selectedStockTake" />
                  </vs-popup>
                </div> -->
                <div v-if="isMobileView && popupActive" class="form-container">
                  <editStocktakeForm
                    :selectedStockTake="selectedStockTake"
                    @onClose="handleCancel"
                    @fetchStocktake="getClinicDrugbookProducts"
                  />
                </div>

                <div
                  v-if="totalTreatmentsWithNoEntry > 0"
                  class="p-2 rounded"
                  style="
                    background-color: rgba(234, 84, 85, 0.12);
                    color: #ea5455;
                    max-width: 909px;
                  "
                >
                  <p>
                    <b>Drugbook Records Pending</b> - You have
                    {{ totalTreatmentsWithNoEntry }} treatments without drugbook
                    entries. Please complete the Drugbook for these treatments
                    in the mobile app first.
                  </p>
                </div>
                <!-- <div
                  v-if="!completeComplianceCheck"
                  class="flex items-center lg:p-5 lg:flex"
                >
                  <vs-button
                    @click="onStocktake()"
                    color="success"
                    type="border"
                    v-if="!completeComplianceCheck"
                    :disabled="totalTreatmentsWithNoEntry > 0"
                  >
                    Stocktake
                  </vs-button>
                </div> -->
              </div>

              <div
                v-if="errors.includes('drug')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
              >
                <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2"
                  >Please complete a stocktake to confirm your drugbook
                  balances.</span
                >
              </div>
              <div class="w-full flex space-x-4 flex-wrap my-6">
                <div
                  :key="image.photoLink"
                  class="w-24"
                  v-for="(image, index) in images.drug"
                >
                  <div
                    @click="ImagePreview(image.photoLink, 'drug')"
                    v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index"
                  >
                    <vs-image
                      class="w-full hover:shadow-lg hover-scale object-cover"
                      :src="image.photoLink"
                    />
                  </div>
                  <template
                      v-if="image.photoLink.indexOf('pdf') > -1"
                    >
                      <div
                      v-if="!isDigitalDrubook"
                        :key="index"
                        @click="DownloadPdf({ url: image.photoLink, name: 'drug' })"
                      >
                        <img
                          class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                        <p class="text-center text-sm">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} ${complianceYear}`}}
                        </p>
                      </div>
                      <div
                        :key="index+'digital'"
                        v-else
                        @click="DownloadPdf({ url: image.photoLink, name: `${complianceDetails.clinicName} - Drugbook - Monthly Compliance` })"
                      >
                        <img
                          class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                        <p class="text-center text-sm">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} ${complianceYear}`}}
                        </p>
                      </div>
                  </template>
                </div>
              </div>
            </div>
            <!-- <div v-if="!completeComplianceCheck" class="items-center lg:p-5 hidden lg:flex">
              <div class="vs-con-loading__container overflow-visible" style="text-align: center; position: relative">
                <vs-button @click="onStocktake()" color="success" type="border" v-if="!completeComplianceCheck"
                  :disabled="totalTreatmentsWithNoEntry > 0">
                  Stocktake
                </vs-button>
              </div>
            </div> -->
          </div>
          <vs-divider v-if="toggleScreenSize" />
          <div class="w-full flex mt-5 flex-no-wrap" v-if="toggleScreenSize">
            <div class="w-full">
              <div class="w-full hidden lg:block">
                <p class="text-2xl font-bold">Fridge Temperatures</p>
                <p>
                  Please upload photos of your frige temperature monitoring book
                </p>
              </div>
              <div class="lg:hidden flex justify-between w-full">
                <div>
                  <p class="text-xl font-bold">
                    Fridge Temperatures
                  </p>
                  <p class="text-sm">
                    Please upload photos of your frige temperature monitoring
                    book
                  </p>
                </div>
                <div class="flex items-center">
                  <div class="vs-con-loading__container overflow-visible relative">
                    <label class="upload-btn bg-white text-center px-6 py-1 flex items-center rounded-lg text-primary font-medium">
                    <span>
                      <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                      <span> Upload </span>
                      <input
                        type="file"
                        accept="image/*,application/pdf,.heic"
                        @change="uploadImage($event, 'fridge')"
                        id="fridge-file-input"
                        hidden
                        multiple
                      />
                      <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                    </span>
                </label>
                  </div>
                </div>
              </div>
              <div
                v-if="errors.includes('fridge') && !images.fridge.length"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
              >
                <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2"
                  >Upload at least one(1) image</span
                >
              </div>
              <div class="w-full flex space-x-4 flex-wrap my-6">
                <div
                  :key="image.photoLink"
                  class="w-24"
                  v-for="(image, index) in images.fridge"
                >
                  <div
                    @click="ImagePreview(image.photoLink, 'fridge')"
                    v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index"
                  >
                    <vs-image
                      class="w-full hover:shadow-lg hover-scale object-cover"
                      :src="image.photoLink"
                    />
                  </div>
                  <template
                      v-if="image.photoLink.indexOf('pdf') > -1"
                    >
                      <div
                      v-if="!isDigitalDrubook"
                        :key="index"
                        @click="DownloadPdf({ url: image.photoLink, name: 'drug' })"
                      >
                        <img
                          class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                         <!-- [Clinic Name] [Drugbook] - [Month] [Year] -->
                         <p class="text-center text-sm">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} ${complianceYear}`}}
                         </p>
                      </div>
                      <div
                        :key="index+'digital'"
                        v-else
                        @click="DownloadPdf({ url: image.photoLink, name: `${complianceDetails.clinicName} - Drugbook - Monthly Compliance` })"
                      >
                        <img
                          class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                        <p class="text-center text-sm">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} ${complianceYear}`}}
                        </p>
                      </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="items-center p-5 hidden lg:flex">
              <div class="vs-con-loading__container overflow-visible relative">
                  <label class="upload-btn bg-white text-center px-6 py-1 flex items-center rounded-lg text-primary font-medium">
                    <span>
                      <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                      <span> Upload </span>
                      <input
                        type="file"
                        accept="image/*,application/pdf,.heic"
                        @change="uploadImage($event, 'fridge')"
                        id="fridge-file-input"
                        hidden
                        multiple
                      />
                      <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                    </span>
                </label>
              </div>
            </div>
          </div>
          <vs-divider v-if="toggleScreenSize" />
          <div class="w-full flex mt-5 flex-no-wrap" v-if="toggleScreenSize">
            <div class="w-full">
              <div class="w-full hidden lg:block">
                <p class="text-2xl font-bold">Stock Photos</p>
                <p>
                  Please upload photos of your stock (fridge and cupboard stock)
                </p>
              </div>
              <div class="lg:hidden flex justify-between w-full">
                <div>
                  <p class="text-xl font-bold">
                    Stock Photos
                  </p>
                  <p class="text-sm">
                    Please upload photos of your stock (fridge and cupboard
                    stock)
                  </p>
                </div>
                <div class="flex items-center">
                  <div class="vs-con-loading__container overflow-visible relative">
                    <label class="upload-btn bg-white text-center px-6 py-1 flex items-center rounded-lg text-primary font-medium">
                      <span>
                        <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                        <span> Upload </span>
                        <input
                          type="file"
                          accept="image/*,application/pdf,.heic"
                          @change="uploadImage($event, 'stock')"
                          id="fridge-file-input"
                          hidden
                          multiple
                        />
                        <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-if="errors.includes('stock') && !images.stock.length"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
              >
                <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2"
                  >Upload at least one(1) image</span
                >
              </div>
              <div class="w-full flex space-x-4 flex-wrap my-6">
                <div
                  :key="image.photoLink"
                  class="w-24"
                  v-for="(image, index) in images.stock"
                >
                  <div
                    @click="ImagePreview(image.photoLink, 'stock')"
                    v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index"
                  >
                    <vs-image
                      class="w-full hover:shadow-lg hover-scale object-cover"
                      :src="image.photoLink"
                    />
                  </div>
                  <div
                    :key="index"
                    v-if="image.photoLink.indexOf('pdf') > -1"
                    @click="
                      DownloadPdf({ url: image.photoLink, name: 'stock' })
                    "
                  >
                    <img
                      class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                      :key="image.index"
                      src="@/assets/images/icon-pdf.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="items-center p-5 hidden lg:flex">
              <div class="vs-con-loading__container overflow-visible relative">
                  <label class="upload-btn bg-white text-center px-6 py-1 flex items-center rounded-lg text-primary font-medium">
                    <span>
                      <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                      <span> Upload </span>
                      <input
                        type="file"
                        accept="image/*,application/pdf,.heic"
                        @change="uploadImage($event, 'stock')"
                        id="fridge-file-input"
                        hidden
                        multiple
                      />
                      <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                    </span>
                </label>
              </div>
            </div>
          </div>
          <vs-divider v-if="toggleScreenSize" />
          <div class="flex flex-no-wrap w-full mt-5" v-if="toggleScreenSize">
            <div class="w-full">
              <div class="hidden w-full lg:block">
                <p class="text-2xl font-bold ">Emergency Medication Photos</p>
                <p>
                  Please upload photos of your Hyalase and Adrenaline expiry dates
                </p>
              </div>

              <div class="flex justify-between w-full lg:hidden">
                <div>
                  <p class="text-xl font-bold">
                    Emergency Medication Photos
                  </p>
                  <p class="text-sm">
                    Please upload photos of your Hyalase and Adrenaline expiry dates
                  </p>
                </div>
                <div class="flex items-center">
                  <div class="vs-con-loading__container relative">
                    <label class="upload-btn bg-white text-center px-6 py-1 flex items-center rounded-lg text-primary font-medium">
                      <span>
                        <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                        <span> Upload </span>
                        <input type="file" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'emergency')" id="fridge-file-input" hidden multiple />
                        <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="errors.includes('emergency')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span">
                <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2">Upload at least one(1) image</span>
              </div>
              <div class="flex flex-wrap w-full">
                <div :key="index" class="w-24 m-2" v-for="(image, index) in images.emergency">
                  <div @click="ImagePreview(image.photoLink, 'emergency')" v-if="image.photoLink.indexOf('pdf') < 0"
                    :key="index">
                    <vs-image class="object-cover w-full hover:shadow-lg hover-scale" :key="index"
                      :src="image.photoLink" />
                  </div>
                  <div :key="index" v-if="image.photoLink.indexOf('pdf') > -1"
                    @click="DownloadPdf({ url: image.photoLink, name: 'emergency' })">
                    <img class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale" :key="image.index"
                      src="@/assets/images/icon-pdf.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="items-center hidden lg:flex">
              <div class="vs-con-loading__container relative">
                <label class="upload-btn bg-white text-center px-6 py-1 flex items-center rounded-lg text-primary font-medium">
                  <span>
                    <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                    <span> Upload </span>
                    <input type="file" accept="image/*,application/pdf,.heic" @change="uploadImage($event, 'emergency')" id="fridge-file-input" hidden multiple />
                    <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <vs-divider v-if="toggleScreenSize" />
        </div>
        <div
          class="w-full flex justify-end items-center"
          v-if="toggleScreenSize"
        >
          <p class="m-5">Step 1 of 2</p>
          <vs-button size="lg" color="primary" @click="saveImages"
            >Next</vs-button
          >
        </div>
      </div>
    </div>
    <div :class="`popUpCustom ${!isMobileView ? `block` : `hidden`}`">
      <vs-popup
        title="Edit Record"
        :active.sync="popupActive"
        v-if="!isMobileView && isMobileView !== null"
      >
        <editStocktakeForm
          :selectedStockTake="selectedStockTake"
          @onClose="handleCancel"
          @fetchStocktake="getClinicDrugbookProducts"
          :popupActive="popupActive"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import heic2any from "heic2any";
import _ from "lodash";
import complainceDrugbookTable from "@/views/components/org-owner/compliance/drugbook.vue";
import editStocktakeForm from "@/views/components/org-owner/compliance/editStocktakeForm.vue";
import ProgressBar from '@/views/components/drugbook/ProgressBar.vue';

export default {
  components: {
    complainceDrugbookTable,
    editStocktakeForm,
    ProgressBar,
  },
  inject: ["getRoleRouteName"],
  data() {
    return {
      isDigitalDrubook: false,
      isStocktake: false,
      totalTreatmentsWithNoEntry: 0,
      completeComplianceCheck: false,
      complianceDrugbook: [],
      totalDocs: 0,
      selectedStockTake: {},
      popupImageActivo: false,
      popupActive: false,
      imagePreviewSrc: { src: "" },
      status: "In Review",
      orgId: null,
      complianceId: "",
      complianceMonth: "",
      complianceYear: "",
      complianceDetails: {},
      errors: [],
      images: {
        drug: [],
        fridge: [],
        stock: [],
        emergency: [],
      },
      isMobileView: false,
      onEdit: true,
      showDetails: true,
    };
  },
  // components: {
  //   Review,
  // },
  methods: {
    ...mapActions("monthlyCompliance", [
      "udpatebookingDetails",
      "uploadFiles",
      "downloadPdf",
      "updatePhotos",
      "fetchDetails",
      "fetchUnsetProducts",
      "fetchClinicDrugbookProducts",
      "fetchStocktakeFlagFeature",
    ]),
    ...mapActions("drugbook", [
      "fetchDrugEntries",
      "fetchDrugEntriesPDF",
    ]),
    async getStocktakeFlagFeature() {
      try {
        const res = await this.fetchStocktakeFlagFeature({
          orgId: this.orgId,
          clinicId: this.complianceDetails.clinicId,
        });
        this.isStocktake = res.data.data.status;
      } catch (e) {
        return;
      }
    },
    async getClinicDrugbookProducts() {
      this.$vs.loading();
      try {
        const res = await this.fetchClinicDrugbookProducts({
          clinicId: this.complianceDetails.clinicId,
          complianceId: this.complianceId,
        });
        if (res.data && res.data.data) {
          const count = _.countBy(res.data.data, function (drug) {
            return drug.fromDrugStocktake;
          });
          if (count.true > 0) {
            this.completeComplianceCheck = true;
          } else {
            this.completeComplianceCheck = false;
          }
          this.complianceDrugbook = res.data.data;
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        return;
      }
      // }
    },
    async getUnsetProducts() {
      this.$vs.loading();
      try {
        const res = await this.fetchUnsetProducts({
          clinicId: this.complianceDetails.clinicId,
          month: moment(this.complianceDetails.updatedAt).format("MM"),
          year: moment(this.complianceDetails.updatedAt).format("YYYY"),
        });
        if (res.data.data && res.data.data.length > 0) {
          this.totalTreatmentsWithNoEntry =
            res.data.data[0].totalTreatmentsWithNoEntry;
          if (this.totalTreatmentsWithNoEntry === 0) {
            this.getClinicDrugbookProducts();
          }
        }
        this.getClinicDrugbookProducts();
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        return;
      }
    },
    ImagePreview(src, imagefor = "") {
      this.popupImageActivo = true;
      this.imagePreviewSrc = { src: src, imagefor: imagefor };
    },
    async genratePdfReport(){
      this.$vs.loading()
      try {
        const res = await this.fetchDrugEntriesPDF({
          page: 1,
          limit: Number.MAX_SAFE_INTEGER,
          selectedClinic: this.complianceDetails.clinicId,
          isCurrentDateMinus30Days: true,
        });
        res.data.data.photoLink = res.data.data.Location;
        res.data.data.group = 'drugbook'
        this.images['drug'] = [
          res.data.data,
        ];
        this.$vs.notify({
            title: "Success",
            text: "Exported PDF from drugbook.",
            color: "success",
          });

      } catch (error) {
        this.$vs.notify({
            title: "Failed",
            text:   "Export from drugbook. Try again!",
            color: "danger",
          });
      }
      //uncomment to auto download expported PDF
      // await this.DownloadPdf({
      //   url: res.data.data.Location,
      //   name: `${this.complianceDetails.clinicName}-${this.complianceMonth}-${moment(this.complianceDetails.updatedAt).format("YYYY")}-drugbook`
      // })
      this.$vs.loading.close()
    },
    async DownloadPdf({ url, name }) {
      const res = await this.downloadPdf(url);
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name.includes('Monthly Compliance') ? `${name} - ${moment(this.complianceDetails.createdAt).subtract(1,'month').format('MMMM')}` : `${name}-monthly-compliance.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    removePreview() {
      const index = _.findIndex(this.images[this.imagePreviewSrc.imagefor], [
        "photoLink",
        this.imagePreviewSrc.src,
      ]);
      this.images[this.imagePreviewSrc.imagefor].splice(index, 1);
      if (this.images[this.imagePreviewSrc.imagefor].length === 0) {
        this.errors.push(this.imagePreviewSrc.imagefor);
      }
      this.imagePreviewSrc = { src: "" };
      this.popupImageActivo = false;
    },
    async getDetails(clinicId) {
      const res = await this.fetchDetails({ clinicId: clinicId });
      if (res.data.data.length > 0) {
        this.complianceDetails = res.data.data[0];
        this.complianceMonth = moment(res.data.data[0].createdAt).subtract(1,'month').format(
          "MMMM"
        );
        this.complianceYear = moment(res.data.data[0].createdAt).subtract(1,'month').format(
          "YYYY"
        );
        this.images.drug = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "drugbook",
        ]).map((item) => item);
        this.images.fridge = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "fridgetemperature",
        ]).map((item) => item);
        this.images.stock = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "stockPhotos",
        ]).map((item) => item);
        this.images.emergency = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "emergencyPhotos",
        ]).map((item) => item);
      }
    },
    handleValidateDrugbook() {
      if (!this.isStocktake) {
        if (this.images.drug.length === 0) {
          return true;
        }
        return false;
      }
      return false;
    },
    async saveImages() {
      try {
        const isDrugBookValid = this.handleValidateDrugbook();
        if (
          isDrugBookValid ||
          this.images.fridge.length === 0 ||
          this.images.stock.length === 0 ||
          this.images.emergency.length === 0
        ) {
          this.errors = _.pullAll(
            [
              isDrugBookValid ? "drug" : undefined,
              this.images.fridge.length === 0 ? "fridge" : undefined,
              this.images.stock.length === 0 ? "stock" : undefined,
              this.images.emergency.length === 0 ? "emergency" : undefined,
            ],
            [undefined]
          );
          this.$vs.notify({
            title: "Failed",
            text: "Upload image for all category. Try again!",
            color: "danger",
          });
        } else {
          this.$vs.loading();
          const drugbook = this.images.drug;
          const fridgetemperature = this.images.fridge;
          const stockPhotos = this.images.stock;
          const emergencyPhotos = this.images.emergency;
          const data = {
            payload: {
              uploadedPhotos: [
                ...drugbook,
                ...fridgetemperature,
                ...stockPhotos,
                ...emergencyPhotos,
              ],
            },
            clinicId: this.complianceId,
          };
          const res = await this.updatePhotos(data);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Your progress has been saved.",
            color: "success",
          });
          this.$router.push({
            name: this.getRoleRouteName("ComplianceStepUpload"),
            params: { clinicId: this.complianceId },
          });
        }
      } catch (error) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Failed to save progress. Try again!",
          color: "danger",
        });
      }
    },
    toggleModal(data) {
      this.selectedStockTake = { ...data };
      this.popupActive = true;
    },
    onStocktake() {
      this.$router
        .push({
          name: "orgOwnerCompliancedrugbookStocktake",
          params: {
            clinicId: this.complianceDetails.clinicId,
            complianceId: this.complianceId,
          },
        })
        .catch(() => {});
    },
    async uploadImage(event, imagefor = "") {
      try {
        if (
          event.target.files[0]["type"].startsWith("image") ||
          event.target.files[0]["type"].startsWith("image/heic") ||
          event.target.files[0]["type"].indexOf("pdf") > -1
        ) {
          this.$vs.loading();
          let files = new FormData();
          const fileArray = Object.keys(event.target.files).map((item) => item);
          for (var i = 0; i < fileArray.length; i++){
            if(event.target.files[[fileArray[i]]]["type"].startsWith("image/heic")){
              const blob = new Blob([event.target.files[fileArray[i]]], { type: "image/heic" })
              const result = await heic2any({
                blob,
                toType: "image/jpeg",
                quality: 1,
              })
              var newfile = new File([result], "image.jpeg");
              files.append(`files[${fileArray[i]}]`, newfile);
            }
            else{
              files.append(`files[${fileArray[i]}]`, event.target.files[fileArray[i]]);
            }
          }

          const res = await this.uploadFiles(files);
          this.images[imagefor] = [
            ...this.images[imagefor],
            ...res.data.map((item) => {
              item.photoLink = item.Location;
              item.group =
                imagefor === "drug"
                  ? "drugbook"
                  : imagefor === "fridge"
                  ? "fridgetemperature"
                  : imagefor === "stock"
                  ? "stockPhotos"
                  : imagefor === "emergency"
                  ? "emergencyPhotos"
                  : "";
              return item;
            }),
          ];
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } catch (err) {
        this.$vs.loading.close();
        if (err && err.status === 415) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        } else {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },
    getStatusData(data) {
      const status = {
        upNext: "Up Next",
        failed: "Missed",
        skipped: "Skipped",
        inReview: "Ready for Review",
        changeRequested: "Change Requested",
        completed: "Completed",
      };

      return status[data];
    },
    handleCancel() {
      this.popupActive = false;
      this.selectedStockTake = {};
    },
    handleOnEdit(data) {
      this.onEdit = data;
    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
  },
  computed: {
    toggleScreenSize: function () {
      if (this.isMobileView && this.isMobileView !== null) {
        return !this.popupActive;
      }
      return true;
    },
    monthCompliance: function () {
      return moment(this.complianceDetails.createdAt).subtract(1,'month').format('MM')
    },
    yearCompliance: function (){
      return moment(this.complianceDetails.createdAt).subtract(1,'month').format('YYYY')
    }
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();

  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  created: async function () {
    this.complianceId = this.$route.params.clinicId;
    await this.getDetails(this.complianceId);
    const res = await this.fetchDrugEntries({
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
      selectedClinic: this.complianceDetails.clinicId,
    });
    this.isDigitalDrubook = res.data.data.docs.length > 0;
    this.orgId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    // this.getStocktakeFlagFeature();
    this.getUnsetProducts();
    this.genratePdfReport();
  },
};
</script>

<style lang="scss">

.upload {
  &-btn {
    border: 1px solid #467969;
  }
}

.tableHeight {
  height: 60vh;
}

.vs-image--img {
  background-position: 50%;
  padding-bottom: 100%;
  background-size: cover;
  display: block;
  position: relative;
}

.upnext,
.inreview {
  background-color: #e5f8ee;
  color: #84cdb8;
}

.skipped {
  background-color: #e2e8e6;
  color: #074230;
}

.popUpCustom .vs-popup--content {
  overflow: auto;
}

/* .form-container {
  min-height: 40vh;
} */

.failed {
  background-color: #fbe4e4;
  color: #ea5455;
}

.changerequested {
  background-color: #ff9f43;
  color: #fff2e3;
}

.completed {
  background-color: #e2e8e6;
  color: #074230;
}
</style>
